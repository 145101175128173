html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a, a:hover, button {
  cursor: pointer;
  text-decoration: none;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease; }

body {
  font-family: 'Muli', sans-serif; }

#root {
  padding-top: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh; }

header {
  background-color: #000;
  padding: 20px 0px; }
  header .header-outer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
  header .header-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    header .header-left .logo {
      display: block; }
    header .header-left ul {
      margin-left: 60px; }
      header .header-left ul li {
        display: inline-block;
        margin: 0 10px; }
        header .header-left ul li.active a {
          color: #000; }
        header .header-left ul li a {
          padding: 0 5px;
          color: #fff; }
          header .header-left ul li a:hover {
            color: #7F7F7F !important; }
        header .header-left ul li:first-child {
          margin-left: 0; }
          header .header-left ul li:first-child a {
            padding-left: 0;
            color: #fff; }
        header .header-left ul li:last-child {
          margin-right: 0; }
          header .header-left ul li:last-child a {
            padding-right: 0; }
  header .header-right .header-btn {
    width: 140px;
    height: 40px;
    margin-left: 20px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid #404040;
    border-radius: 5px; }
    header .header-right .header-btn.header-btn-highlight {
      border-color: #000;
      color: #000; }
      header .header-right .header-btn.header-btn-highlight:hover {
        background-color: #000;
        color: #fff; }
  header a {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase; }
    header a:hover {
      color: #000; }

.MuiButton-root.button-border {
  width: 175px;
  height: 45px;
  font-size: 14px !important;
  border: 1px solid #7F7F7F;
  border-radius: 4px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #fff;
  color: #7F7F7F;
  text-transform: uppercase;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-family: "Roboto", sans-serif; }
  .MuiButton-root.button-border:hover {
    -webkit-box-shadow: none;
    box-shadow: none; }

.MuiButton-root.button-solid {
  width: 175px;
  height: 45px;
  font-size: 14px !important;
  border: 1px solid #7F7F7F;
  border-radius: 4px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #000;
  color: #BFBFBF;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif; }
  .MuiButton-root.button-solid:hover {
    background-color: #000;
    color: #000;
    -webkit-box-shadow: none;
    box-shadow: none; }

.MuiFormHelperText-root {
  margin-left: 4px !important; }

.srv-validation-message {
  font-size: 12px;
  color: #f00; }

.login-box {
  max-width: 700px;
  border: 1px solid #DFDFDF;
  border-radius: 4px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  padding: 50px; }
  .login-box h1 {
    font-size: 20px;
    font-weight: bold; }
  .login-box p {
    font-size: 14px;
    color: 'grey'; }

.outerWrap {
  min-height: calc(100vh - 80px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .outerWrap .main-row {
    min-height: calc(100vh - 80px); }

.login-wrap {
  max-width: 700px;
  margin: 20px auto;
  border: 1px solid #DFDFDF;
  border-radius: 4px;
  padding: 40px 60px 80px 60px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center; }
  .login-wrap h1 {
    font-size: 30px;
    margin-bottom: 10px;
    color: #000;
    font-weight: 700;
    font-family: "Playfair Display", serif; }
  .login-wrap .login-sub-text {
    font-size: 16px;
    margin-bottom: 10px;
    color: #7F7F7F;
    font-weight: 600; }
  .login-wrap .makeStyles-margin-2 {
    margin: 0; }
  .login-wrap .MuiFormControl-root {
    margin: 15px 0; }
  .login-wrap .MuiFormLabel-root {
    font-size: 14px; }
  .login-wrap .MuiSelect-selectMenu {
    text-align: left; }
  .login-wrap .MuiInputBase-input {
    background-color: #fff; }
  .login-wrap .MuiSelect-select:focus {
    background-color: #fff;
    border-color: red; }
  .login-wrap .MuiSelect-icon {
    color: #000; }
  .login-wrap .loginSubBtn {
    background-color: #000;
    color: #BFBFBF;
    height: 45px;
    margin-top: 20px;
    font-family: "Roboto", sans-serif; }
    .login-wrap .loginSubBtn:hover {
      background-color: #fff;
      color: #000;
      border: 1px solid #000; }
  .login-wrap .jss3.jss4 {
    -webkit-box-shadow: none;
    box-shadow: none; }
  .login-wrap .makeStyles-root-3.makeStyles-focused-4 {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #000; }

.login-signup {
  padding-top: 20px;
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px solid #d6d5d5; }
  .login-signup label {
    font-size: 12px;
    color: #404040; }
    .login-signup label a {
      color: #404040;
      text-decoration: underline; }

.dummy-class {
  min-height: calc(100vh - 80px); }
  .dummy-class h1 {
    color: #fff; }

.profile-quote-bg {
  text-align: center; }
  .profile-quote-bg .profile-quote {
    display: inline-block; }
    .profile-quote-bg .profile-quote h1 {
      margin-top: 90px;
      font-size: 48px;
      line-height: 64px;
      color: #fff;
      font-family: "Playfair Display", serif;
      white-space: pre-line;
      text-align: center;
      position: relative; }
      .profile-quote-bg .profile-quote h1:before {
        content: '';
        position: absolute;
        left: -50px;
        top: -10px;
        background-image: url("/assets/images/quote-start.svg");
        width: 45px;
        height: 40px;
        background-repeat: no-repeat;
        background-position: center; }
      .profile-quote-bg .profile-quote h1:after {
        content: '';
        position: absolute;
        right: 0px;
        bottom: -10px;
        background-image: url("/assets/images/quote-stop.svg");
        width: 45px;
        height: 40px;
        background-repeat: no-repeat;
        background-position: center; }

.tab-head {
  padding-top: 20px;
  margin-bottom: 30px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#EEEEEE), color-stop(0%, #FFFFFF), to(#E7E7E7));
  background-image: -webkit-linear-gradient(top, #EEEEEE 0%, #FFFFFF 0%, #E7E7E7 100%);
  background-image: -o-linear-gradient(top, #EEEEEE 0%, #FFFFFF 0%, #E7E7E7 100%);
  background-image: linear-gradient(180deg, #EEEEEE 0%, #FFFFFF 0%, #E7E7E7 100%); }
  .tab-head button {
    font-size: 18px;
    letter-spacing: 0.14px;
    text-transform: capitalize; }
    .tab-head button.Mui-selected {
      background: #FFFFFF;
      -webkit-box-shadow: 0 -1px 2px 0 rgba(181, 181, 181, 0.5);
      box-shadow: 0 -1px 2px 0 rgba(181, 181, 181, 0.5);
      border-radius: 5px 5px 0 0; }
      .tab-head button.Mui-selected:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
        outline: none; }
  .tab-head .MuiTabs-indicator {
    display: none; }

.user-table {
  margin-bottom: 80px; }
  .user-table .MuiTableCell-root:first-child {
    padding-left: 0; }
  .user-table .MuiTableHead-root {
    border-bottom: 2px solid #000; }
  .user-table .MuiTableCell-head {
    font-size: 16px;
    color: #000;
    font-weight: 700; }
  .user-table .user-detail {
    text-transform: capitalize; }
  .user-table .user-detail-url a {
    color: #000;
    text-decoration: underline; }
    .user-table .user-detail-url a:hover {
      text-decoration: none; }
  .user-table .add-del-btn img {
    cursor: pointer;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease; }

.footer {
  margin-top: auto;
  padding: 15px 0;
  background: #FFFFFF;
  -webkit-box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.2);
  font-family: Muli-Bold; }
  .footer img {
    padding: 7px 0;
    padding-right: 100px;
    border-right: 1px solid #E0E0E0; }
  .footer span {
    font-size: 14px; }
    .footer span a {
      color: #000; }
      .footer span a:hover {
        text-decoration: underline; }

.common_list {
  min-height: calc(100vh - 95px); }
  .common_list table tr td:last-child button {
    padding-right: 0; }

.common-button {
  border: 1px solid #000 !important; }

button:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none; }

.common-table-wrap .page-title {
  border: 0;
  margin-bottom: 0; }
  .common-table-wrap .page-title h1 {
    font-size: 24px;
    font-weight: 400; }
  .common-table-wrap .page-title .button-solid {
    width: 140px;
    -webkit-box-shadow: none;
    box-shadow: none; }

.common-table-wrap .common-table {
  margin-top: 30px; }
  .common-table-wrap .common-table table thead .MuiTableCell-root {
    padding: 20px 16px;
    font-size: 16px;
    font-weight: 700;
    border-bottom: 2px solid #000; }
    .common-table-wrap .common-table table thead .MuiTableCell-root:first-child {
      padding-left: 0; }
  .common-table-wrap .common-table table tbody .MuiTableCell-root {
    padding: 21px 16px;
    font-size: 14px;
    font-weight: 300; }
    .common-table-wrap .common-table table tbody .MuiTableCell-root:first-child {
      padding-left: 0; }
    .common-table-wrap .common-table table tbody .MuiTableCell-root :last-child .MuiTableCell-root {
      border-bottom: 0; }
  .common-table-wrap .common-table .table-profile-details {
    font-size: 14px;
    font-weight: 300; }
    .common-table-wrap .common-table .table-profile-details img {
      margin-right: 10px; }
    .common-table-wrap .common-table .table-profile-details label {
      font-size: 16px;
      margin-bottom: 3px;
      font-weight: 600; }
  .common-table-wrap .common-table .action-btn button {
    padding: 10px; }
    .common-table-wrap .common-table .action-btn button:focus {
      outline: 0; }
  .common-table-wrap .common-table.common-table-th-top-border table thead .MuiTableCell-root {
    border-top: 2px solid #000; }

.rating {
  padding: 2px 5px;
  margin-left: 10px;
  font-size: 13px;
  color: #fff;
  background-color: #000;
  border-radius: 5px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 400; }
  .rating b {
    display: block; }
  .rating img {
    width: 13px;
    margin-top: -2px;
    margin-right: 2px !important;
    display: block; }

.account-search {
  padding: 30px 0;
  border-bottom: 1px solid #DFDFDF; }
  .account-search .MuiFormControl-root {
    width: 100%; }
  .account-search .search-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .account-search .search-wrap .search-item {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      margin-right: 12px; }
      .account-search .search-wrap .search-item:last-child {
        margin-right: 0;
        -webkit-box-flex: .8;
        -ms-flex: .8;
        flex: .8; }
      .account-search .search-wrap .search-item .button-solid {
        height: 56px;
        letter-spacing: 1.25px; }
  .account-search .MuiSelect-select:focus {
    background-color: #fff !important; }

.dashboad .dash_widgets {
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #DFDFDF; }
  .dashboad .dash_widgets h2 {
    border: 1px solid #DFDFDF;
    border-radius: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .dashboad .dash_widgets h2 span {
      padding: 18px;
      font-weight: 600;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .dashboad .dash_widgets h2 span img {
        width: 20px;
        margin-right: 10px; }
    .dashboad .dash_widgets h2 count {
      padding: 20px;
      border-left: 1px solid #DFDFDF;
      width: 70px;
      font-weight: 600; }

.dashboad .dash_tables {
  padding-top: 30px;
  padding-bottom: 30px; }
  .dashboad .dash_tables h3 {
    font-size: 24px;
    margin-bottom: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-family: "Playfair Display", serif; }
    .dashboad .dash_tables h3 a {
      font-size: 12px;
      letter-spacing: .4px;
      color: #000;
      font-family: "Muli", sans-serif;
      text-transform: uppercase;
      text-decoration: underline; }
  .dashboad .dash_tables .dash_tables_list {
    border: 1px solid #DFDFDF;
    padding: 20px;
    border-radius: 10px; }
    .dashboad .dash_tables .dash_tables_list li {
      border-bottom: 1px solid #DFDFDF;
      margin-bottom: 20px;
      padding-bottom: 20px; }
      .dashboad .dash_tables .dash_tables_list li a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center; }
        .dashboad .dash_tables .dash_tables_list li a .pro-pic {
          margin-right: 10px;
          width: 50px;
          border-radius: 50%;
          height: 50px;
          -o-object-fit: cover;
          object-fit: cover; }
        .dashboad .dash_tables .dash_tables_list li a h4 {
          font-size: 1rem;
          margin-bottom: 5px;
          font-weight: 700;
          color: #000;
          text-transform: capitalize; }
        .dashboad .dash_tables .dash_tables_list li a .dashboad-event-date-time span {
          display: -webkit-inline-box;
          display: -ms-inline-flexbox;
          display: inline-flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          font-size: 12px;
          margin-right: 10px;
          font-weight: 300;
          color: #7F7F7F;
          max-width: 70%; }
          .dashboad .dash_tables .dash_tables_list li a .dashboad-event-date-time span img {
            width: 15px;
            border-radius: 0%;
            margin-right: 5px; }
        .dashboad .dash_tables .dash_tables_list li a:hover h4 {
          color: #000; }
          .dashboad .dash_tables .dash_tables_list li a:hover h4 span {
            color: #000; }
      .dashboad .dash_tables .dash_tables_list li:last-child {
        margin-bottom: 0;
        border: none;
        padding-bottom: 0; }
    .dashboad .dash_tables .dash_tables_list.account_list li a h4 {
      margin-bottom: 5px; }
    .dashboad .dash_tables .dash_tables_list.account_list li a img {
      border-radius: 0; }
    .dashboad .dash_tables .dash_tables_list.account_list li a span {
      font-size: 14px;
      line-height: 16px;
      font-weight: 300;
      color: #000; }

.common-table-wrap thead .MuiTableCell-root {
  padding: 20px 16px;
  font-size: 16px;
  font-weight: 700;
  border-bottom: 2px solid #000; }

.common-table-wrap .common-table {
  margin-top: 30px; }
  .common-table-wrap .common-table table thead .MuiTableCell-root {
    padding: 20px 16px;
    font-size: 16px;
    font-weight: 700;
    border-bottom: 2px solid #000; }
    .common-table-wrap .common-table table thead .MuiTableCell-root:first-child {
      padding-left: 0; }
  .common-table-wrap .common-table table tbody .MuiTableCell-root {
    padding: 21px 16px;
    font-size: 14px;
    font-weight: 300; }
    .common-table-wrap .common-table table tbody .MuiTableCell-root:first-child {
      padding-left: 0; }
    .common-table-wrap .common-table table tbody .MuiTableCell-root :last-child .MuiTableCell-root {
      border-bottom: 0; }
  .common-table-wrap .common-table .table-profile-details {
    font-size: 14px;
    font-weight: 300; }
    .common-table-wrap .common-table .table-profile-details img {
      margin-right: 10px; }
    .common-table-wrap .common-table .table-profile-details label {
      font-size: 16px;
      margin-bottom: 3px;
      font-weight: 600; }
  .common-table-wrap .common-table .action-btn button {
    padding: 10px; }
    .common-table-wrap .common-table .action-btn button:focus {
      outline: 0; }
  .common-table-wrap .common-table.common-table-th-top-border table thead .MuiTableCell-root {
    border-top: 2px solid #000; }
  .common-table-wrap .common-table.pitch-listing-table tbody td {
    font-size: 16px;
    font-weight: 600; }

.common-table-wrap .pitch-list-image {
  width: 60px;
  height: 60px;
  -o-object-fit: cover;
  object-fit: cover;
  margin-right: 10px; }

.common-table-wrap th:first-child, .common-table-wrap .user-detail {
  padding-left: 0 !important; }

.common-table-wrap td:last-child {
  padding-right: 0; }

.approved-list-table .page-title {
  padding-bottom: 30px; }

.dropdown-arrow-color .MuiSelect-icon {
  color: #000; }

.dropdown-arrow-color button .MuiSvgIcon-root {
  color: #000; }

@media (max-width: 575.98px) {
  header {
    padding: 15px 0px; }
    header .header-left .logo img {
      width: 100px; }
    header .header-left ul {
      margin-left: 10px;
      display: none; }
      header .header-left ul li {
        display: inline-block;
        margin: 0 10px; }
        header .header-left ul li a {
          padding: 0 5px; }
    header .header-right .header-btn {
      width: 100px;
      height: 30px;
      margin-left: 15px; }
    header a {
      font-size: 14px;
      font-weight: 600;
      color: #fff;
      text-transform: uppercase; }
      header a:hover {
        color: #000; }
  .outerWrap {
    min-height: calc(100vh - 60px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .login-wrap {
    max-width: 100%;
    padding: 20px; }
    .login-wrap h1 {
      font-size: 25px; }
    .login-wrap .login-sub-text {
      font-size: 12px; }
    .login-wrap .makeStyles-margin-2 {
      margin: 10px 0; }
    .login-wrap .MuiFormLabel-root {
      font-size: 12px; }
    .login-wrap .loginSubBtn {
      height: 35px;
      margin-top: 10px; }
  .login-signup {
    padding-top: 10px;
    margin-top: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }
    .login-signup label {
      margin-bottom: 10px; }
  .button-border {
    width: 100px;
    height: 35px;
    font-size: 12px !important; }
  .button-solid {
    width: 100px;
    height: 35px;
    font-size: 12px !important; } }
